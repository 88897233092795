import React, { useEffect, useState } from "react";
import AreaChart from "./AreaChart";
const SecondRow = ({ usersDashboard }) => {
  const [clientData, setClientData] = useState()
  useEffect(() => {
    setClientData(usersDashboard?.usersWithMoreCons)
  }, [usersDashboard])

  const symptomNames = {
    dentalPain: "Dental Pain",
    jawAche: "Jaw Ache",
    headacheMigraine: "Headache/Migraine",
    ulcer: "Ulcer",
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body h-480">
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 className="title_text ff-inter">Top Symptoms Results </h4>

                  {/* <span>
                    <div className="searcg_icon">
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search-alt cgrey"></span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </span> */}
                </div>
                <div className="tab_le_dealer">
                  <div className="table-responsive table-defaut-design">
                    <table
                      id=""
                      className="table dt-responsive ff-inter active_dealer nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Outcome</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody className="td_color">

                        {usersDashboard?.symptomResult?.map((elem, i) => (
                          <tr className="dashboard_row">
                            <td>{i + 1}</td>
                            <td>{symptomNames[elem?.symptom]}</td>
                            <td>{elem?.count}</td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body  h-480">
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 className="title_text ff-inter">Top Clients </h4>
                </div>
                <div className="tab_le_dealer">
                  <div className="table-responsive table-defaut-design">
                    <table
                      id=""
                      className="table dt-responsive ff-inter active_dealer nowrap w-100"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Bookings</th>
                        </tr>
                      </thead>
                      <tbody className="td_color">
                        {clientData &&
                          clientData?.map((item, i) => {
                            return (
                              <tr className="dashboard_row">
                                <td>{i + 1}</td>
                                <td>{item?.name}</td>
                                <td>{item?.consultations}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row ">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body  h-480">
                <div className="garph_1">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 className="title_text">User Income (Online)</h4>
                  </div>
                  {/* <div className="days_moth d-block">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Day
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Week
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link active bg-grey-btn" href="#">
                          Month
                        </a>
                      </li>
                    </ul>
                  </div> 
                  
                    {usersDashboard?.incomePerMonth?.reduce((a, c, i) => {
                          return i == 1
                            ? (a.income += c.income)
                            : (a += c.income);
                        })} 

                  
                  */}
                  <div className="tot_trd_vech pt-3 d-block">
                    <p className="mb-2">Total </p>
                    <h5>
                      <b>£ {usersDashboard?.totalIncomeOnline}</b>
                    </h5>
                  </div>
                  <div id="spline_area" className="apex-charts" dir="ltr">
                    <AreaChart
                      monthArr={usersDashboard?.incomePerMonth?.map(
                        (ob) => ob.month
                      )}
                      dataArr={usersDashboard?.incomePerMonth?.map(
                        (ob) => ob.income
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body  h-480">
                <div className="garph_2">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                    <h4 className="title_text">User Income (Practice)</h4>
                  </div>
                  {/* <div className="days_moth">
                    <ul className="nav nav-pills">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Day
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Week
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link active bg-grey-btn" href="#">
                          Month
                        </a>
                      </li>
                    </ul>
                  </div> */}``
                  <div className="tot_trd_vech pt-3">
                    <p className="mb-2">Total Sales</p>
                    <h5>
                      <b>1720</b>
                    </h5>
                  </div>
                  <div id="spline_area1" className="apex-charts" dir="ltr">
                    <AreaChart />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondRow;
