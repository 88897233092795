import { useFormik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createDentist, editDentist } from "../../../Redux/Actions/admin/adminPanel";
import { toast } from "react-toastify";

const DentitstModal = ({ show, handleClose, dentistDetail }) => {

    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};

        if (!values.firstName) {
            errors.firstName = "Required";
        }
        if (!values.lastName) {
            errors.lastName = "Required";
        }
        if (!values.dob) {
            errors.dob = "Required";
        }
        if (!values.email) {
            errors.email = "Required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
        if (!values.phnNumber) {
            errors.phnNumber = "Required";
        }
        if (!values.postCode) {
            errors.postCode = "Required";
        }
        if (!values.uni_attended) {
            errors.uni_attended = "Required";
        }
        if (!values.highest_qualification) {
            errors.highest_qualification = "Required";
        }
        if (!values.year_of_grad) {
            errors.year_of_grad = "Required";
        }
        if (!values.gdc_no) {
            errors.gdc_no = "Required";
        }
        if (!values.dbs_no) {
            errors.dbs_no = "Required";
        }
        if (!values.indemnity_provider) {
            errors.indemnity_provider = "Required";
        }
        if (!values.indemnity_number) {
            errors.indemnity_number = "Required";
        }

        return errors;
    };


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: dentistDetail?._id || "",
            firstName: dentistDetail?.firstName || "",
            lastName: dentistDetail?.lastName || "",
            email: dentistDetail?.email || "",
            phnNumber: dentistDetail?.phnNumber || "",

            // =====================================================================================

            dob: dentistDetail?.dob && typeof dentistDetail?.dob === "string" && dentistDetail?.dob?.includes("T")
            ? dentistDetail.dob.split("T")[0]
            : dentistDetail?.dob || "",
            houseNo: dentistDetail?.address?.houseNo || "",
            street: dentistDetail?.address?.street || "",
            townCity: dentistDetail?.address?.townCity || "",
            postCode: dentistDetail?.address?.postCode || "",
            uni_attended: dentistDetail?.uni_attended || "",
            highest_qualification: dentistDetail?.highest_qualification || "",
            year_of_grad: dentistDetail?.year_of_grad || "",
            gdc_no: dentistDetail?.gdc_no || "",
            dbs_no: dentistDetail?.dbs_no || "",
            indemnity_provider: dentistDetail?.indemnity_provider || "",
            indemnity_number: dentistDetail?.indemnity_number || "",
        },
        validate,
        onSubmit: async (values) => {
            try {
                if (dentistDetail) {
                    dispatch(editDentist(values)).then((data) => {
                        if (data?.payload?.success) {
                            toast.success(data?.payload?.message)
                        } else {
                            toast.error(data?.payload?.message)
                        }
                    });
                } else {
                    dispatch(createDentist(values)).then((data) => {
                        if (data?.payload?.success) {
                            toast.success(data?.payload?.message)
                        } else {
                            toast.error(data?.payload?.message)
                        }
                    });
                }
                formik.resetForm();
                handleClose()

            } catch (error) {
                console.log(error);
            }
        },
    });

    console.log(dentistDetail, "dentistDetail")

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Create Dentist
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="popup-input-element">
                            <div className="form-group mb-3">
                                <label htmlFor="">First Name</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="First Name"
                                    name="firstName"
                                    value={formik.values.firstName}
                                />
                                {formik.errors.firstName ? (
                                    <p className="formik-errors">{formik.errors.firstName}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="">Last Name</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="lastname"
                                    name="lastName"
                                    value={formik.values.lastName}
                                />
                                {formik.errors.lastName ? (
                                    <p className="formik-errors">{formik.errors.lastName}*</p>
                                ) : null}
                            </div>

                            {/* ======================================================================================================== */}

                            <div className="form-group mb-3">
                                <label htmlFor="">DOB</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="dob"
                                    name="dob"
                                    value={formik.values.dob}
                                />
                                {formik.errors.dob ? (
                                    <p className="formik-errors">{formik.errors.dob}*</p>
                                ) : null}
                            </div>

                            {/* ========================================================================================================= */}

                            <div className="form-group mb-3">
                                <label htmlFor="">Email Address</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Email Address"
                                    name="email"
                                    value={formik.values.email}
                                />
                                {formik.errors.email ? (
                                    <p className="formik-errors">{formik.errors.email}*</p>
                                ) : null}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="">Phone Number</label>
                                <div className="d-flex align-items-center phn-form-control">
                                    <div className="country_code">
                                        <p>+44</p>
                                    </div>
                                    <div className="phn-form-control_width">
                                        <input
                                            type="number"
                                            className="form-control "
                                            onChange={formik.handleChange}
                                            placeholder=""
                                            name="phnNumber"
                                            value={formik.values.phnNumber}
                                        />
                                    </div>

                                </div>

                                {formik.errors.phnNumber ? (
                                    <p className="formik-errors">{formik.errors.phnNumber}*</p>
                                ) : null}
                            </div>


                            {/* ========================================================================================================== */}
                            <div className="form-group mb-3">
                                <label htmlFor="houseNo">First Line of Address</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="First Line of Address"
                                    name="houseNo"
                                    value={formik.values.houseNo}
                                />
                                {formik.errors.houseNo ? (
                                    <p className="formik-errors">{formik.errors.houseNo}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="street">Second Line of Address (Optional)</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Second Line of Address"
                                    name="street"
                                    value={formik.values.street}
                                />
                                {formik.errors.street ? (
                                    <p className="formik-errors">{formik.errors.street}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="townCity">Town/City</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Town/City"
                                    name="townCity"
                                    value={formik.values.townCity}
                                />
                                {formik.errors.townCity ? (
                                    <p className="formik-errors">{formik.errors.townCity}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="postCode">Post Code</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Post Code"
                                    name="postCode"
                                    value={formik.values.postCode}
                                />
                                {formik.errors.postCode ? (
                                    <p className="formik-errors">{formik.errors.postCode}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="uni_attended">University Attended</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="University Attended"
                                    name="uni_attended"
                                    value={formik.values.uni_attended}
                                />
                                {formik.errors.uni_attended ? (
                                    <p className="formik-errors">{formik.errors.uni_attended}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="highest_qualification">Highest Qualification Level</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Highest Qualification Level"
                                    name="highest_qualification"
                                    value={formik.values.highest_qualification}
                                />
                                {formik.errors.highest_qualification ? (
                                    <p className="formik-errors">{formik.errors.highest_qualification}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="year_of_grad">Year of Graduation</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Year of Graduation"
                                    name="year_of_grad"
                                    value={formik.values.year_of_grad}
                                />
                                {formik.errors.year_of_grad ? (
                                    <p className="formik-errors">{formik.errors.year_of_grad}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="gdc_no">GDC Number</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="GDC Number"
                                    name="gdc_no"
                                    value={formik.values.gdc_no}
                                />
                                {formik.errors.gdc_no ? (
                                    <p className="formik-errors">{formik.errors.gdc_no}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="dbs_no">DBS Number</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="DBS Number"
                                    name="dbs_no"
                                    value={formik.values.dbs_no}
                                />
                                {formik.errors.dbs_no ? (
                                    <p className="formik-errors">{formik.errors.dbs_no}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="indemnity_provider">Indemnity Provider</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Indemnity Provider"
                                    name="indemnity_provider"
                                    value={formik.values.indemnity_provider}
                                />
                                {formik.errors.indemnity_provider ? (
                                    <p className="formik-errors">{formik.errors.indemnity_provider}*</p>
                                ) : null}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="indemnity_number">Indemnity Number</label>
                                <input
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    placeholder="Indemnity Number"
                                    name="indemnity_number"
                                    value={formik.values.indemnity_number}
                                />
                                {formik.errors.indemnity_number ? (
                                    <p className="formik-errors">{formik.errors.indemnity_number}*</p>
                                ) : null}
                            </div>


                            {/* =========================================================================================================== */}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="btn_submit ">
                            <button
                                type="submit"
                                className="submit-btn-popup"
                            >
                                Submit
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default DentitstModal