import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllBecomeDoctor } from "../../../Redux/Actions/admin/adminPanel";



const DentistRequests = () =>{
  const dispatch = useDispatch();
  const becomeDoctors = useSelector((state) => state.adminPanel.becomeDoctors);

  useEffect(()=>{
    dispatch(getAllBecomeDoctor());
  },[])
    
    return (
     <>
   
        <Layout>         
        <div id="layout-wrapper" className="bg-dash admin-table">
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div>
                          <span className="small_text">Dentists</span>
                          <h4 className="mb-sm-0 font-size-28">
                          Dentist Requests
                            <span className="header-title-text">
                              9
                            </span>
                          </h4>
                        </div>
                        <div className="page-title-right">
                          <form className="app-search d-none d-lg-block ">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="position-relative w-100">
                                <input type="text" className="form-control" placeholder="Search" />
                                <span className="bx bx-search"></span>
                              </div>
                              {/* <img
                                src={admin_avatar_header}
                                className="avata_serch"
                                alt=""
                              /> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="row mb-4">
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                    <input type="text" class="form-control filter-input-element" id=""  placeholder="Search Here" />
                    </div>
                    <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                        <div class="form-group">
                            <select class="form-control filter-select-element">
                                <option>Status</option>
                                <option>2</option>
                            </select>
                        </div>
                    </div>
                </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="members_tbl">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                              <h4 className="title_text">Dentist Requests</h4>                          
                            </div>
                            <div className="table-responsive table-defaut-design dealer-table-c">
                              <table
                                id="datatable"
                                className="table  vehicles_table  w-100"
                              >
                                <thead>
                                  <tr>
                                    <th>Created</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th></th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody className="td_color trans_table_fix_height">                               
                                <tr>
                                <td>25/07/2023</td>
                                <td>John Smith</td>
                                <td>johnsmith@yopmail.com</td>
                                <td></td>
                                <td>
                                <div className="drop-select">
                                <select class="form-control td-select-element">
                                <option>Not Approved</option>
                                <option>Approved</option>
                                </select>
                                </div>
                                </td>                               
                                </tr>                               
                                </tbody>
                              </table>
                            </div>
                            <div className="row">
                          <div className="col-sm-12 col-md-12">
                            <div className="select-table-bottom-r d-flex justify-content-end">
                              <label>
                                Results per page
                                <select
                                  onChange={(event) =>
                                    setPage(event.target.value)
                                  }
                                  className="select-page-count-bottom"
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </label>
                            </div>
                          </div>
                        </div>
                          </div>
                        </div>                  
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </Layout>
     </>
        
    )
}

export default DentistRequests