import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { assignNewDoctor, getNewRequests } from "../../../Redux/Actions/admin/adminPanel";
import moment from "moment";
import ConfirmPopup from "./ConfirmPopup";



const ConsultationsRequests = () => {

  const dispatch = useDispatch();
  const new_requests = useSelector((state) => state.adminPanel.new_requests)
  const [modalShow, setModalShow] = React.useState(false);
  const [consId, setConsId] = useState();
  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined
  })

  useEffect(() => {
    dispatch(getNewRequests({ filter: filter }));
  }, [])

  console.log(new_requests, "new_requests")

  const handleFilter = (e) => {
    let { value, name } = e.target;
    setFilter({ ...filter, [name]: value });
    dispatch(getNewRequests({ filter: { ...filter, [name]: value } }));
  }



  return (
    <>
      <ConfirmPopup modalShow={modalShow} setModalShow={setModalShow} consId={consId} />
      <Layout>
        <div id="layout-wrapper" className="bg-dash admin-table">
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                      <div>
                        <span className="small_text">Consultations</span>
                        <h4 className="mb-sm-0 font-size-28">
                          New Requests
                          <span className="header-title-text">
                            {new_requests?.upcoming?.filter((data) => !data?.assignedDoctor)?.length}
                          </span>
                        </h4>
                      </div>
                      {/* <div className="page-title-right">
                        <form className="app-search d-none d-lg-block ">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="position-relative w-100">
                              <input
                                //name="search"
                                type="text"
                                className="form-control"
                                placeholder="Search"
                              // onChange={(e) => handleFilter(e)}
                              />
                              <span className="bx bx-search"></span>
                            </div>
                            <img
                              src={admin_avatar_header}
                              className="avata_serch"
                              alt=""
                            />
                          </div>
                        </form>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                    <input
                      name="search"
                      type="text"
                      class="form-control filter-input-element"
                      id=""
                      placeholder="Search Here"
                      onChange={(e) => handleFilter(e)}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">
                      <input
                        name="date"
                        type="date"
                        class="form-control filter-input-element"
                        id=""
                        placeholder="Search Here"
                        onChange={(e) => handleFilter(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="members_tbl">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">New Requests</h4>
                          </div>
                          <div className="table-responsive table-defaut-design dealer-table-c">
                            <table className="table  vehicles_table  w-100">
                              <thead>
                                <tr>
                                  <th>Created</th>
                                  <th>Name</th>
                                  <th>Past Consultations</th>
                                  <th>Paid to Date</th>
                                  <th>Requested Date</th>
                                  <th>Status</th>
                                  {/* {
                                    sessionStorage.getItem("role") != 'admin' && <th>Actions</th>
                                  } */}


                                </tr>
                              </thead>
                              <tbody className="td_color trans_table_fix_height">
                                {
                                  new_requests?.upcoming?.map((item, i) => {
                                    return (
                                      <>
                                        {
                                          !item?.assignedDoctor && <tr>
                                            <td>{moment(item?.createdAt).fromNow()}</td>
                                            <td>{item?.user_id?.firstName + " " + item?.user_id?.lastName}</td>
                                            <td>-</td>
                                            <td>£{item?.price}</td>
                                            <td>{moment(item?.date).format(
                                              "DD/MM/YYYY hh:mm"
                                            )}</td>
                                            <td>{item?.assignedDoctor ? "Accepted" : "Pending"} </td>
                                            {/* {
                                              sessionStorage.getItem("role") != 'admin' && <td valign="middle" className={item?.assignedDoctor ? " disable_consult viewBtn" : "viewBtn"}>
                                                <Link
                                                  to=""
                                                >
                                                  <button
                                                    onClick={() => { setModalShow(true); setConsId(item?._id) }}
                                                    //onClick={() => handleAssignDoctor(item?._id)}
                                                    className="btn pending_genr "
                                                    disabled={item?.assignedDoctor ? true : false}
                                                  >
                                                    {item?.assignedDoctor ? "Accepted" : "Accept"}
                                                  </button>
                                                </Link>
                                              </td>
                                            } */}

                                          </tr>
                                        }

                                      </>
                                    )
                                  })
                                }
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12">
                              <div className="select-table-bottom-r d-flex justify-content-end">
                                <label>
                                  Results per page
                                  <select
                                    onChange={(event) =>
                                      setPage(event.target.value)
                                    }
                                    className="select-page-count-bottom"
                                  >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                  </select>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </Layout>
    </>

  )
}

export default ConsultationsRequests